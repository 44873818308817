import React from 'react';
import AppThemeProvider from '../provider/AppThemeProvider';
import RouteApp from './routeApp';


function RouteInit() {
    return (
            <AppThemeProvider>
                <RouteApp />
            </AppThemeProvider>
    );
}

export default RouteInit;