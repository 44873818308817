import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import login from "./reducers/login";
import homeReducer from "./reducers/homeReducer";
import contactReducer from "./reducers/contactReducer";


const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  login,
  homeReducer,
  contactReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});


// window.addEventListener('beforeunload', () => {
//   localStorage.clear();
// });