import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fName: "",
  lName: "",
  email: "",
  phone: "",
  remark: "",
  company:"",
  tax:"",
  city:"",
  state:"",
  country:"",
  house:"",
  street:"",
  postalCode:"",
  billingAddress:false,
  transfer:false,
};

const contactReducer = createSlice({
  name: "contactData",
  initialState,
  reducers: {
    updateContact: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetContact: () => {
      return initialState;
    },
    scrollTransfer: (state, action) => {
      state.transfer =  action.payload;
    },
  },
});

export const { updateContact, resetContact , scrollTransfer} = contactReducer.actions;
export default contactReducer.reducer;
