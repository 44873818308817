import React, { lazy, Suspense, useEffect } from "react";
import { paths } from "../config/constants";
import { Route, Routes, useLocation } from "react-router-dom";
import Error from "../pages/Error";
import AppLayout from "../component/layout/AppLayout";
import { PageLoading } from "../component/includes/PageLoading";
import DriverLayout from "../component/layout/DriverLayout";
import Bussinesslayout from "../component/layout/Bussinesslayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { resetContact } from "../redux/reducers/contactReducer";
// import { resetHomeRe } from "../redux/reducers/homeReducer";
// import { reset } from "../redux/reducers/login";

//Common
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const Qans = lazy(() => import("../pages/Qans"));
const SocialLogin = lazy(() => import("../pages/SocialLogin"));
const Imprint = lazy(() => import("../pages/Imprint"));
const BussinessAccount = lazy(() => import("../pages/BussinessAccount"));
const NewsAndUpdate = lazy(() => import("../pages/NewsAndUpdate"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const TermCondition = lazy(() => import("../pages/TermAndCondition"));
const Done = lazy(() => import("../pages/Done"));
const Summary = lazy(() => import("../pages/Summary"));
const ContactInfo = lazy(() => import("../pages/ContactInfo"));
const VehicleOption = lazy(() => import("../pages/VehicleOption"));
const EditProfile = lazy(() => import("../pages/EditProfile"));
const MyRide = lazy(() => import("../pages/MyRide"));
const Home = lazy(() => import("../pages/Home"));

//Bussiness Agent
const AgentTermsConditons = lazy(() =>
  import("../pages/BussinessAgent/TermsAndConditons")
);
const BusinesHome = lazy(() => import("../pages/BussinessAgent/Home"));
const BusinesVehicleoption = lazy(() =>
  import("../pages/BussinessAgent/VehivleOption")
);
const BusinesContact = lazy(() => import("../pages/BussinessAgent/Contact"));
const BusinesSummary = lazy(() => import("../pages/BussinessAgent/Summery"));
const BusinesDone = lazy(() => import("../pages/BussinessAgent/Done"));
const BusinessRides = lazy(() =>
  import("../pages/BussinessAgent/BusinessRides")
);
const BusinessRegisterStaff = lazy(() =>
  import("../pages/BussinessAgent/StaffRegister")
);
//driver
const DriverTermsConditons = lazy(() =>
  import("../pages/driver/DriverTermsAndCondition")
);
const RideHistory = lazy(() => import("../pages/driver/RideHistory"));
const OpenBooking = lazy(() => import("../pages/driver/OpenBooking"));
const DriverVehical = lazy(() => import("../pages/driver/DriveAndVehicle"));
const RideDetails = lazy(() => import("../pages/driver/RideDetails"));
const PaymentDtl = lazy(() => import("../pages/driver/PaymentDtl"));
const TaxValues = lazy(() => import("../pages/driver/TaxValues"));
const DriverEditProfile = lazy(() =>
  import("../pages/driver/DriverEditProfile")
);

//auth
const ForgetPassword = lazy(() => import("../pages/auth/ForgetPassword"));
const ChangePassword = lazy(() => import("../pages/auth/ChangePassword"));
const LogIn = lazy(() => import("../pages/auth/Login"));
const DriverRegistration = lazy(() =>
  import("../pages/auth/DriverRegistration")
);
const PartnerRegister = lazy(() => import("../pages/auth/PartnerRegister"));

function RouteApp() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // const dispatch = useDispatch();
  // const navigate =  useNavigate();
  // const reduxdata = useSelector((state) => state);
  // let token = reduxdata?.login?.token;

// useEffect(() => {
//   const storedTokenTimestamp = localStorage.getItem('tokenTimestamp');
//   if (token) {
//     const currentTime = Date.now();
//     const storedTimestamp = parseInt(storedTokenTimestamp, 10);
//     const tokenExpirationTime =30000; 
//     if (currentTime - storedTimestamp >= tokenExpirationTime) {
//       handleExpire();
//     }
//   }
// }, [token]);

//   const handleExpire =() => {
         
//       toast.error("Session Expired!",);
//       dispatch(resetContact());
//       dispatch(resetHomeRe());
//       dispatch(reset());
//       sessionStorage.clear();
//       // localStorage.clear();
//       navigate(paths.accounts.login);
//       toast.success("Logout Successfully! ",);

//   }
  return (
    <>
      <Routes>
        {/* auth page */}
        <Route path={paths.accounts.home} element={<AppLayout />}>
          <Route
            path={paths.accounts.forgetPassword+'/:token'}
            element={
              <Suspense fallback={<PageLoading />}>
                <ForgetPassword />
              </Suspense>
            }
          />
          <Route
            path={paths.accounts.changePassword}
            element={
              <Suspense fallback={<PageLoading />}>
                <ChangePassword />
              </Suspense>
            }
          />
          <Route
            path={paths.accounts.driverRegister}
            element={
              <Suspense fallback={<PageLoading />}>
                <DriverRegistration />
              </Suspense>
            }
          />
          <Route
            path={paths.accounts.partnerRegister}
            element={
              <Suspense fallback={<PageLoading />}>
                <PartnerRegister />
              </Suspense>
            }
          />
          <Route
            path={paths.accounts.login}
            element={
              <Suspense fallback={<PageLoading />}>
                <LogIn />
              </Suspense>
            }
          />
          <Route
            path={paths.accounts.socialLogin}
            element={
              <Suspense fallback={<PageLoading />}>
                <SocialLogin />
              </Suspense>
            }
          />
        </Route>

        {/* drivers page */}
        <Route path={paths.driver.home} element={<DriverLayout />}>
          <Route
            path={paths.driver.driveVehicle}
            element={
              <Suspense fallback={<PageLoading />}>
                <DriverVehical />
              </Suspense>
            }
          />
          <Route
            path={paths.driver.taxValues}
            element={
              <Suspense fallback={<PageLoading />}>
                <TaxValues />
              </Suspense>
            }
          />
          <Route
            path={paths.driver.rideDetails}
            element={
              <Suspense fallback={<PageLoading />}>
                <RideDetails />
              </Suspense>
            }
          />
          <Route
            path={paths.driver.paymentDetails}
            element={
              <Suspense fallback={<PageLoading />}>
                <PaymentDtl />
              </Suspense>
            }
          />
           <Route
            path={paths.driver.openBooking}
            element={
              <Suspense fallback={<PageLoading />}>
                <OpenBooking />
              </Suspense>
            }
          />
           <Route
            path={paths.driver.rideHistory}
            element={
              <Suspense fallback={<PageLoading />}>
                <RideHistory />
              </Suspense>
            }
          />
          <Route
            path={paths.driver.driverEditProfile}
            element={
              <Suspense fallback={<PageLoading />}>
                <EditProfile />
              </Suspense>
            }
          />
          <Route
            path={paths.driver.driverTermsCondition}
            element={
              <Suspense fallback={<PageLoading />}>
                <DriverTermsConditons />
              </Suspense>
            }
          />
        </Route>

        {/* businessAgent */}

        <Route path={paths.businessAgent.home} element={<Bussinesslayout />}>
          <Route
            path={paths.businessAgent.home}
            element={
              <Suspense fallback={<PageLoading />}>
                <BusinesHome />
              </Suspense>
            }
          />
          <Route
            path={paths.businessAgent.vehicle}
            element={
              <Suspense fallback={<PageLoading />}>
                <BusinesVehicleoption />
              </Suspense>
            }
          />
          <Route
            path={paths.businessAgent.contact}
            element={
              <Suspense fallback={<PageLoading />}>
                <BusinesContact />
              </Suspense>
            }
          />
      
          <Route
            path={paths.businessAgent.summary}
            element={
              <Suspense fallback={<PageLoading />}>
                <BusinesSummary />
              </Suspense>
            }
          />
          <Route
            path={paths.businessAgent.done}
            element={
              <Suspense fallback={<PageLoading />}>
                <BusinesDone />
              </Suspense>
            }
          />
          <Route
            path={paths.businessAgent.editProfile}
            element={
              <Suspense fallback={<PageLoading />}>
                <DriverEditProfile />
              </Suspense>
            }
          />
          <Route
            path={paths.businessAgent.rideDetails}
            element={
              <Suspense fallback={<PageLoading />}>
                <BusinessRides />
              </Suspense>
            }
          />
          <Route
            path={paths.businessAgent.taxValues}
            element={
              <Suspense fallback={<PageLoading />}>
                <TaxValues />
              </Suspense>
            }
          />
          <Route
            path={paths.businessAgent.termsCondition}
            element={
              <Suspense fallback={<PageLoading />}>
                <AgentTermsConditons />
              </Suspense>
            }
          />
        </Route>


        {/* common pages */}
        <Route path={paths.home} element={<AppLayout />}>
          <Route
            index
            element={
              <Suspense fallback={<PageLoading />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path={paths.vehicle}
            element={
              <Suspense fallback={<PageLoading />}>
                <VehicleOption />
              </Suspense>
            }
          />
          <Route
            path={paths.contact}
            element={
              <Suspense fallback={<PageLoading />}>
                <ContactInfo />
              </Suspense>
            }
          />
          <Route
            path={paths.summary}
            element={
              <Suspense fallback={<PageLoading />}>
                <Summary />
              </Suspense>
            }
          />
          <Route
            path={paths.done}
            element={
              <Suspense fallback={<PageLoading />}>
                <Done />
              </Suspense>
            }
          />
          <Route
            path={paths.termCondition}
            element={
              <Suspense fallback={<PageLoading />}>
                <TermCondition />
              </Suspense>
            }
          />
          <Route
            path={paths.aboutUs}
            element={
              <Suspense fallback={<PageLoading />}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path={paths.newsAndUpdate}
            element={
              <Suspense fallback={<PageLoading />}>
                <NewsAndUpdate />
              </Suspense>
            }
          />
          <Route
            path={paths.bussinessAccount}
            element={
              <Suspense fallback={<PageLoading />}>
                <BussinessAccount />
              </Suspense>
            }
          />
          <Route
            path={paths.imprint}
            element={
              <Suspense fallback={<PageLoading />}>
                <Imprint />
              </Suspense>
            }
          />
          <Route
            path={paths.editProfile}
            element={
              <Suspense fallback={<PageLoading />}>
                <EditProfile />
              </Suspense>
            }
          />
          <Route
            path={paths.myRide}
            element={
              <Suspense fallback={<PageLoading />}>
                <MyRide />
              </Suspense>
            }
          />
          <Route
            path={paths.agentProfile}
            element={
              <Suspense fallback={<PageLoading />}>
                <EditProfile />
              </Suspense>
            }
          />
          <Route
            path={paths.agentRideDetails}
            element={
              <Suspense fallback={<PageLoading />}>
                <BusinessRides />
              </Suspense>
            }
          />
          <Route
            path={paths.agentTaxValues}
            element={
              <Suspense fallback={<PageLoading />}>
                <TaxValues />
              </Suspense>
            }
          />
          <Route
            path={paths.agentTermsCondition}
            element={
              <Suspense fallback={<PageLoading />}>
                <AgentTermsConditons />
              </Suspense>
            }
          />
           <Route
            path={paths.privacyPolicy}
            element={
              <Suspense fallback={<PageLoading />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
            <Route
            path={paths.qans}
            element={
              <Suspense fallback={<PageLoading />}>
                <Qans />
              </Suspense>
            }
          />
               <Route
            path={paths.agentRegisterStaff}
            element={
              <Suspense fallback={<PageLoading />}>
                <BusinessRegisterStaff />
              </Suspense>
            }
          />
        </Route>

        <Route
          path={"*"}
          element={
            <Suspense fallback={<PageLoading />}>
              <Error />
            </Suspense>
          }
        />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default RouteApp;
