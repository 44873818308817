import { createSlice } from '@reduxjs/toolkit';
// import dayjs from 'dayjs';

const initialState = {
  bookingId:"",
  transferType: '',
  pickUpDateTime: null,
  from: '',
  to:"",
  distance: 0,
  estimatedTravelTime: '0',
  extraTime:"0",
  person:"1",
  returnFrom:"",
  returnTo:"",
  returnPerson:"1",
  returnTravelTime:"0",
  returnExtraTime:"0",
  returnDateTime: null,
  returnDistance:"0",
  fromLat: "",
  fromLong: "",
  toLat: "",
  toLong: "",
};

const homeReducer = createSlice({
  name: 'homeData',
  initialState,
  reducers: {
    updateForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetHomeRe: () => {
      return initialState;
    },
  },
});

export const { updateForm, resetHomeRe } = homeReducer.actions;
export default homeReducer.reducer;
