import React from 'react'
import logo from '../../Assets/Images/logo.png'
import { Link } from 'react-router-dom'
import SvgImages from '../../Assets/Images/svgImages';
import twitter from '../../Assets/Images/img_twitter.png'
import instagram from '../../Assets/Images/img_instagram.png'
import facebook from '../../Assets/Images/img_facebook.png'
import image11 from '../../Assets/Images/img_image11.png'
import Payment from '../../Assets/Images/Payment-Icons.png'
import { useTranslation } from 'react-i18next'
import { paths } from '../../config/constants';
// import AllPayment from '../../Assets/Images/Payments.png'
import AllPayment from '../../Assets/Images/flu-footer.png'
import { ForkRight } from '@mui/icons-material';
import { useState } from 'react';
import { scrollTransfer } from '../../redux/reducers/contactReducer';
import { useDispatch, useSelector } from 'react-redux';

const { LocationSvg, ContactSvg, AvatarSvg } = SvgImages;

export default function Footer() {

  const reduxdata = useSelector((state) => state);
  // console.log("0", reduxdata.contactReducer.transfer)
  const tran = reduxdata?.contactReducer?.transfer
  const [Transfer, setTransfer] = useState(tran);
  const dispatch = useDispatch();
  const handleTrans = () => {
    setTransfer(!Transfer);
    dispatch(scrollTransfer(Transfer))
  }

  const { t } = useTranslation();

 

  return (<>
    <div className='bg-white' style={{ paddingTop: "10%" }}>
      <footer className="bd-footer position-relative py-3 mt-5 " style={{ backgroundColor: "#11191F" }} >
     
        <div className="position-absolute w-100" style={{ top: "-75px" }} >
          <div className='container p-4 rounded ' style={{ background: "#0E4672" }}>
            <div className='row py-2 text-white d-flex align-items-center justify-content-between'>

              <div className='col-md-4 d-flex align-items-center justify-content-center' >
                <AvatarSvg height='34px' width='34px' />
                <b className='fs-2 mx-3'>Robeena Butt</b>
              </div>

              <div className='col-md-4 d-flex align-items-center justify-content-center' >
              <a href="tel:+49 178 8404957" style={{textDecoration:"none", color:"white"}}>
                <ContactSvg height='30px' width='30px' />
                <span className='mx-2'>+49 178 8404957</span>
                </a>
              </div>

              <div className='col-md-4 d-flex align-items-center justify-content-center' >
                <LocationSvg height='30px' width='30px' />
                <span className='mx-2'>{t('foot-location')} : Bonner Str. 31, 65428 Rüsselsheim</span>
              </div>

            </div>
            {/* ------------------------------------- */}
          </div>
        
        </div>
        <div className="container footmargin">
           
          <div className="row mt-5 justify-content-evenly" >
            <div className="col-md-3 col-sm-3 col-xs-3 mt-3" style={{ textAlign: "left" }}>
              <a className="d-inline-flex align-items-center mb-2 link-dark text-decoration-none" href="/" aria-label="Bootstrap">
                <span><img src={logo} id="image-section" /></span>
              </a>
              <ul className="list-unstyled small text-muted">
                <li className="mb-2 text-light footer-blog-text">{t('footerText')}</li>
              </ul>
            </div>
            <div className="col-md-3 px-5 col-sm-3 mt-4">
              <h5 className='text-light footer-text-header'>{t('foot-link')}</h5>
              <ul className="list-unstyled">
                <li className="mb-2"><Link className="footer-link" to="/" onClick={handleTrans}>{t("transfer-booking")}</Link></li>
                <li className="mb-2"><Link className="footer-link" to={paths.aboutUs}>{t("header-aboutUs")}</Link></li>
                <li className="mb-2"><Link className="footer-link" to={paths.accounts.driverRegister}>{t("Become-Partner")}</Link></li>
                {/* <li className="mb-2"><Link className="footer-link" to={paths.newsAndUpdate}>News & Updates</Link></li> */}
                <li className="mb-2"><Link className="footer-link" to={paths.privacyPolicy}>{t("Privacy-Policy")}</Link></li>
                <li className="mb-2"><Link className="footer-link" to={paths.qans}>{t("QA")}</Link></li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-3 mt-4">
              <h5 className='text-light footer-text-header'>{t('working-hour')}</h5>
              <ul className="list-unstyled">
                <li className="mb-2 pe-5" style={{ color: '#97ADBE' }}>
                  {/* <Link className="footer-link text-header" to="/"> */}
                  {/* We are there for you<br /> around the clock, 24/7,<br /> 365 days a year. */}
                  {t("we-are-there")}
                  {/* </Link> */}
                </li>
                {/* <span className='footer-text-header'>{t('foot-payment')}</span>
                <div>
                  <Link className="footer-link text-header" to="/"><img src={Payment} style={{ width: "15rem" }} /></Link>
                </div> */}
              </ul>
            </div>
            <div className="col-md-3 col-sm-3 mt-4">
              {/* <h5 className='footer-text-header'>Imprint & Contact</h5>
              <div className='footer-text-header fs-6'>Robeena Butt</div>
              <div className='footer-text-header fs-6'>Bonner Str. 31,
                65428 Rüsselsheim</div>
                <div className='footer-text-header fs-6'>+49 178 8404957</div> */}


              <h5 className='footer-text-header'>{t('foot-texNumber')}</h5>
              <ul className="list-unstyled">
                <li className="fs-6" style={{ color: '#97ADBE' }}>2186361508</li>
              </ul>
            </div>

          </div>
        </div>

      </footer>
      <div className='row white-card p-1 px-1'>
        <div className='col-md-5'>
          <div className='d-flex ms-5 gap-2'>


            <div><Link to={"https://www.instagram.com/bluflughafen/"} target='blank'>
              <img src={instagram} width="40px" height="40px" className='icon-round' /></Link></div>
            <div><Link to={"https://www.facebook.com/people/Blu-Flughafen/100068172468945/"} target='blank'> <img src={facebook} width="40px" height="40px" className='icon-round' /></Link></div>
            <div><Link to={"https://www.twitter.com/company/bluflughafen/"} target='blank'>
              <img src={twitter} width="40px" height="40px" className='icon-round' /></Link></div>

          </div>
          {/* style={{display: "flex", flexShrink: 0}} 46.125rem 2.125rem */}
        </div>
        <div className='col-md-7 text-end pe-5'>
          <img src={AllPayment} className='img-fluid' />
        </div>
      </div>
    </div>



  </>)
}


